// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounts-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/accounts.js" /* webpackChunkName: "component---src-pages-accounts-js" */),
  "component---src-pages-getting-started-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-integrations-google-calendar-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/integrations/google-calendar.js" /* webpackChunkName: "component---src-pages-integrations-google-calendar-js" */),
  "component---src-pages-integrations-xero-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/integrations/xero.js" /* webpackChunkName: "component---src-pages-integrations-xero-js" */),
  "component---src-pages-suggestions-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/suggestions.js" /* webpackChunkName: "component---src-pages-suggestions-js" */),
  "component---src-pages-using-reldesk-js": () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/src/pages/using-reldesk.js" /* webpackChunkName: "component---src-pages-using-reldesk-js" */)
}

exports.data = () => import("/www/hosting/distortion-projects/reldesk-api/docs/userdocs/.cache/data.json")

